<template>
  <!-- 新增新闻分类 -->
  <div>
    <!-- 填写表单 -->
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      label-width="120px"
      :rules="rules"
    >
      <el-form-item label="分类标题" prop="categoryName">
        <el-input
          v-model="ruleForm.remark"
          placeholder="请输入标题"
          size="mini"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-radio-group v-model="ruleForm.sort">
          <el-radio :label="1">关闭</el-radio>
          <el-radio :label="2">启用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="序号" prop="serialNumber">
        <el-input
          v-model="ruleForm.status"
          placeholder="请输入标题"
          size="mini"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          v-model="ruleForm.typeName"
          type="textarea"
          placeholder="请输入标题"
          :rows="4"
          style="width: 600px"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <div>
          <el-button type="" @click="getBack">返回</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rescc: [],
      ruleForm: {
        remark: "", // 分类名称
        sort: "", // 是否启用
        status: "", // 序号
        typeName: "", // 备注
      },
      //   验证规则
      rules: {
        remark: [
          { required: true, message: "请输入分类标题", trigger: "blur" },
        ],
        status: [{ required: true, message: "请输入序号", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getBack2();
  },
  methods: {
    getBack2() {
      let newid = this.$route.query.id;
      console.log(newid, "++++++++");
      this.axios
        .get("/admin/consultType/findById", {
          id: newid,
        })
        .then((res) => {
          this.ruleForm = res.data.data;
          console.log(this.ruleForm);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //   返回上一页面
    getBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
